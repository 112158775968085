<template>
  <div class="p-6 flex-auto">
    <h2 class="text-xl font-semibold text-gray-800 mb-3">General info</h2>
    <div v-if="activeProjectName">
      <label class="block text-sm font-medium mb-1 text-gray-600" for="email">Project name <span class="text-rose-500">*</span></label>
      <div class="relative w-[300px]">
        <input
          v-model="projectName"
          id="name"
          class="form-input w-full"
          type="text"
          placeholder="Project name"
        />
        <div class="absolute transform-translate-y top-1/2 right-2 transition-all">
          <XIcon @click="projectName = ''" class="h-5 w-5 text-gray-400 hover:text-gray-600"/>
        </div>
      </div>
      <!-- <span class="text-gray-600 text-xs mt-2.5">You can use up to 11 characters</span> -->
    </div>

    <div class="flex flex-col mt-6">
      <div class="text-sm text-gray-600 font-medium mb-1">Verification code format</div>
      <Dropdown
        :options="[
          { name: '4-digit code', value: 4 },
          { name: '6-digit code', value: 6 }
        ]"
        :selected="code_format.length"
        @change="changeCodeFormat"
      />
    </div>
    <button
      class="btn max-w-[80px] mt-6 bg-indigo-500 hover:bg-indigo-600 text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
      :disabled="loadingSave"
      @click="save"
    >
      <svg v-if="loadingSave" class="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
      </svg>
      <span :class="{'ml-2' : loadingSave}">Save</span>
    </button>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import Dropdown from '@/components/Dropdown/Dropdown.vue'
import { XIcon } from '@heroicons/vue/outline'


export default {
  name: 'SettingsGeneral',
  data: () => ({
    code_format: {
      length: 4
    },
    loadingSave: false,
  }),
  components: {
    Dropdown,
    XIcon,
  },
  watch: {
    code_length: {
      handler(val) {
        this.code_format.length = val
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    ...mapFields('ProjectsModule', {
      active_project: 'active_project.project',
      projectName: 'active_project.project.name',
    }),
    ...mapFields('ProfileModule', {
      code_length: 'code_length'
    }),
    activeProjectName() {
      return this.active_project?.name || ''
    }
  },
  methods: {
    async saveNameProject() {
      const payload = {
        id: this.active_project.id,
        name: this.projectName
      }
      await this.$store.dispatch('ProjectsModule/UPDATE_NAME_PROJECT', payload)
    },
    async saveCodeFormat() {
      const payload = {
        code_length: this.code_format.length,
      }
      await this.$store.dispatch('ProfileModule/UPDATE_PROFILE', payload)
    },
    changeCodeFormat(codeFormat) {
      this.code_format.length = codeFormat
    },

    async save() {
      this.loadingSave = true

      try {
        await Promise.all([this.saveNameProject(), this.saveCodeFormat()]).then(() => {
          setTimeout(() => {
            this.loadingSave = false
          }, 300)
        });
      } catch(e) {
        setTimeout(() => {
          this.loadingSave = false
        }, 300)
      }
    },
  }
}
</script>
